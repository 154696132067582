<template>
  <div class="w-block__post-toolbar-1">
    <v-container v-if="$route.name === 'index'" class="pa-4">
      <v-sheet color="#FCE6B9" dark class="pa-3">
        <v-flex xs12>
          <div class="custom-color">
            Découvrez nos nouveaux podcasts spécial élection !
            <v-btn
              ref="btn"
              color="#FF6E76"
              rounded
              depressed
              class="white--text"
              @click="
                handleOpen(
                  'https://www.apmaudio.fr/universe/679b66ca58dfb34eb6317dfd'
                )
              "
            >
              <v-icon color="#ffffff" class="mr-2"> mdi-volume-high </v-icon>
              Ecoutez les épisodes
            </v-btn>
          </div>
        </v-flex>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__post-toolbar-1 {
  justify-content: center;
  text-align: center;
}
.v-btn__content {
  background-color: transparent;
}
.v-btn::before {
  background-color: transparent;
}
.v-card {
  border-radius: 8px;
}
.v-sheet {
  border-radius: 8px;
}
.custom-color {
  color: #ff6e76;
}
.v-btn {
  border-radius: 4px;
}
</style>
-->
