const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '651138dd213a2f27a58cb838',
    authMode: [['local']],
    nuxt: {
      head: {
        title: 'Apm Audio',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Apm Audio - La Voix des Dirigeants`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: 'Apm',
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Apm Audio',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Apm Audio - La Voix des Dirigeants`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    hasAutoplayContent: true,
    hasSuggestedContent: true,
    WFeedBack: {
      //   ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        title: 'Enregistrez votre message',
        name: 'Messages',
        type: 'audio',
        maxDuration: 150,
        headCaption: `Laissez votre message ! Il sera diffusé lors de la prochaine émission`,
        subCaption: `Je suis informé que apm traite les données recueillies pour permettre la diffusion de dédicaces. Pour en savoir plus,
        reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message à bien été envoyé.`,
        pseudonym: {
          label: 'Nom ou pseudo',
          placeholder: 'Martin',
        },
        recorderForm: [
          {
            label: 'Adresse mail',
            placeholder: 'martin@apm.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: 'Pays',
            placeholder: 'France',
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'Précisez le nom de votre club',
            placeholder: 'Club Bordeaux Rouge',
            value: '',
            type: 'text-area',
            required: false,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '6 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: false,
          },
          {
            label: 'Quel est votre statut ?',
            items: ['Animateur', 'Adhérent', 'Président'],
            value: '',
            type: 'select',
            required: true,
          },
          {
            label: "J’accepte que mon message soit diffusé pendant l'émission",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },

    authLocalOptions: {
      mode: 'otp',
    },
    enableSplashScreen: 'wavy',
    enableExperimentalBlocks: true,
    metadatas: {
      name: 'Apm Audio',
      colorPrimary: '#FF6E76',
      colorAccent: '#FCB88B',
      supportEmailAddress: 'support@apmradio.fr',
    },
    nuxt: {
      robots: {
        UserAgent: '*',
        Disallow: '/',
      },
    },
    analytics: {
      matomo: '42',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          /**{
            type: 'tag',
            tagId: '65117263213a2ffede8cb9dd',
            i18n: {
              fr: {
                title: 'Vos rubriques',
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#FC6F79',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
            filters: {
              type: 'solo',
              soloTagId: '653a704b0ce29a10b3f36ff8',
            },
          },
          {
            type: 'tag',
            tagId: '6511726e213a2f02218cb9e3',
            i18n: {
              fr: {
                title: `Les intégrales`,
                description: `Retrouvez l'intégralité de vos émissions quotidiennes`,
              },
            },
            heading: {
              color: '#FC9C6F',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },*/
          {
            type: 'tag',
            tagId: '664c66760425c736681544f3',
            i18n: {
              fr: {
                title: 'Les intégrales',
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#FC6F79',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '65606b59842dab7d9d6b03b6',
            i18n: {
              fr: {
                title: 'Flash',
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#FEB68D',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '65606b64842dab201e6b03bc',
            i18n: {
              fr: {
                title: "Le quart d'heure expert",
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#533EFD',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '65606b85842dab39e26b03c2',
            i18n: {
              fr: {
                title: 'Le répondeur adhérents',
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#FCAF91',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '65606bcd842dab5fd86b03d9',
            i18n: {
              fr: {
                title: 'Libre antenne animateurs',
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#4AABD5',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
      },
    ],
  },

  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
